.headWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px 0 8px;
    .column2 {
        padding-top: 4px;
    }
}
.head2 {
    padding-top: 4px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

label {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #1b1b1d;
    padding-right: 8px;
}
.value {
    font-size: 14px;
    line-height: 22px;
    color: #1b1b1d;
}
.label1 {
    font-size: 22px;
    color: #1b1b1d;
    font-weight: bold;
    white-space: nowrap;
}
.e1OrderLabel {
    margin-right: 5px;
    white-space: nowrap;
}
.sapOrderLabel {
    margin-right: 5px;
    white-space: nowrap;
}
.value1 {
    margin-left: 10px;
    font-size: 16px;
    line-height: 22px;
    color: #1b1b1d;
    font-weight: bold;
}
.secondLabel {
    color: #54545c;
}

.packItemWrapper {
    margin-top: 8px;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    border-bottom: none;
    // set box-shadow to the bottom and right side of the box
    box-shadow: 0 5px 5px rgba(0,0,0,.05);
    
    .timelineItem {
        width: 100%;
        display: flex;
        min-height: 60px;
        .timelineContent {

            .description {
                color: #70707a;;
            }
        }

        &:first-child {
            .timelineDot, .timelineConnector {
                background-color: #e71316;
            }
            .timelineContent {
                color: #e71316;

                .description {
                    color: #e71316;
                }
                .eventOfTime {
                    font-weight: bold;
                    color: #1b1b1d;
                }
            }

        }
        &:last-child {
            .timelineConnector {
                display: none;
            }
        }
    }
}

.packItemHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    padding: 1rem 15px;

    .row1 {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .column1 {
            width: 52%;
        }
        .column2 {
            display: flex;
            align-items: center;
            img {
                padding-right: 8px;
            }
        }
    }
    .row2 {
        width: 10%;
        text-align: center;
    }
}
.shimentHeader {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid #dcdcdc;
    padding-left: 15px;
    padding-right: 15px;
    .row1 {
        flex: 1 1 38%;
    }
    .row2 {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        img {
            padding-right: 8px;
        }
    }
}


.packItemContentWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px 15px 15px;
    border-bottom: 1px solid #e5e5e5;
    border-radius: 3px;
}


.productItemsWrapper {
    flex: 1 1 44%;
    padding-right: 14px;
}
.productHead {
    margin: 1.2rem 0;
    display: flex;
    align-items: center;
}
.productWrapper {
    display: flex;
    margin-right: 15px;
    padding-bottom: 12px;
}
.parentproductWrapper {
    + .parentproductWrapper {
        border-top: 1px solid #e5e5e5;
    } 
}
.imgWrapper {
    align-self: center;
    flex: 0 0 90px;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}
.pcontentWrapper {
    padding-left: 10px;
}
.carrierWrapper {
    width: 100%;
    .row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 6px;
    }
    .column1 {
        margin-right: 10px;
        margin-top: 6px;
    }
    .column2 {
        margin-top: 6px;
    }
}
.offeringWrapper {
    width: 100%;
    .row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 6px;
    }
    .column1 {
        margin-right: 10px;
        margin-top: 6px;
    }
    .column2 {
        margin-top: 6px;
    }
}
.shipmentWrapper {
    flex: 1 1 46%;
}
@media (max-width: 767px) {
    .imgWrapper {
        flex: 0 0 76px;
        width: 76px;
        height: 70px;
    }
    .packItemHeader {
        .row1 {
            width: 100%;
            .column1 {
                width: 100%;
                padding-bottom: 4px;
            }
        }
    }
    .productItemsWrapper {
        flex: 1 1 100%;
        padding-right: 0;
    }
    .shipmentWrapper {
        flex: 1 1 100%;
    }
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.timelineHead {
    margin: 15px 0;
}

.timelineWrapper {
    width: 100%;
    // .timelineItem {
    //     width: 100%;
    //     display: flex;
    //     min-height: 60px;
    //     .timelineContent {

    //         .description {
    //             color: #70707a;;
    //         }
    //     }

    //     &:first-child {
    //         .timelineDot, .timelineConnector {
    //             background-color: #e71316;
    //         }
    //         .timelineContent {
    //             color: #e71316;

    //             .description {
    //                 color: #e71316;
    //             }
    //             .eventOfTime {
    //                 font-weight: bold;
    //                 color: #1b1b1d;
    //             }
    //         }

    //     }
    //     &:last-child {
    //         .timelineConnector {
    //             display: none;
    //         }
    //     }
    // }

    .timelineSeparator {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .timelineDot {
        display: flex;
        align-self: baseline;
        border-style: solid;
        border-width: 2px;
        padding: 4px;
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
        margin: 11.5px 0px;
        border-color: transparent;
        color: rgb(250, 250, 250);
        background-color: rgb(189, 189, 189);
    }

    .timelineConnector {
        width: 2px;
        background-color: rgb(189, 189, 189);
        -webkit-box-flex: 1;
        flex-grow: 1;
    }
    .timelineContent {
        margin: 0px;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
        flex: 1 1 auto;
        padding: 6px 16px;
        text-align: left;
    }
}
