
* {
  box-sizing: border-box;
}

input {
  appearance: none;
  outline: none;
  border: none;
}

input:-internal-autofill-selected {
  appearance: none !important;
  background-color: #fff !important;
}

.App {
  margin: 0 auto;
  min-height: 100vh;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
}

.App-logo {
  /* height: 40vmin; */
  max-height: 100%;
  padding: 4px 0;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */
.App-headerWrapper {
  margin: auto;
  box-sizing: border-box;
  border-bottom: 1px solid #e5e5e5;
}

.App-header {
  margin: auto;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-head {
  flex: 1 0 auto;
  height: 100%;
  display: flex;
}
.App-Title {
  align-self: center;
  white-space: nowrap;
  margin: 0;
  color: #222;
  margin-left: 0.8rem;
  padding-left: 0.8rem;
  border-left: 1px solid #a2a2a2;
  font-size: 1.2rem;
  font-weight: 500;
}

.App-headright {
  flex: 1 1 auto;
}

.App-locale {
  width: 102px;
  border-radius: 0 !important;
  color: #1b1b1d !important;
  border: 1px solid #ccc !important;

  & > div {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  input {
    border: none !important;
  }

  & fieldset {
    border: none !important;
    border-radius: 0 !important;
  }
}

.vdivider {
  width: 1px;
  height: 100%;
  background-color: #ccc;
}

.loadingMask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.loadingIconWrapper {
  position: absolute;
  left: 50%;
  top: 38%;

  span {
    color: #e71316;
  }
}

@media screen and (max-width: 390px) {
  .App-locale {
    font-size: 14px !important;
    width: 88px;
    & > div {
      padding-left: 10px !important;
      padding-right: 28px !important;
    }
    & svg {
      width: 18px !important;
      height: 18px !important;
    }
  }
}


.App-link {
  color: #3271f0;
  cursor: pointer;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.hdivider {
  height: 1px;
  width: 100%;
  background: #ccc;
}

.vdivider {
  width: 1px;
  height: 100%;
  background: #ccc;
}

.head3 {
  margin: 0;
  padding: 1rem 0;
  font-size: 1.17rem;
  font-weight: bold;
  line-height: 23px;
}

.head5 {
  font-size: 1rem;
  font-weight: 500;
}

.copy {
  margin: 0;
  padding: 0.2rem 0.6rem;
  cursor: pointer;
  color: #1e8ae7;
}

.head3Value {
  margin-left: 8px;
  font-size: 18px;
  font-weight: lighter;
}

.pageContentContainer {
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}
$minHeight: calc(100vh - 68px - 60px - 150px);

.minPageContentContainer {
  min-height: calc(100vh - 68px - 60px - 160px);
}
.mainContainer {
  height: 100%;
  color: #1b1b1d;
  padding: 15px 0 20px 0;
}

.msg {
  margin: 0;
  padding: 0;
  font-size: 12px;
}
.navoutWrapper {
  background-color: #efefef;
}
.navWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 25px;
  .navItem {
    width: 25%;
    //height: 144px;
    box-sizing: border-box;
    /* padding-right: 10px; */
    padding-top: 10px;
    color: #0071d0;
    font-size: 18px;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
  }
  .navInner {
    height: 100px;
    line-height: 30px;
    padding: 30px 0 30px 15px;
    border: 1px solid #ccc;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & .navItem:nth-child(1) {
    .navInner {
      background: url('~/public/images/promocenter.svg') no-repeat center center;
      background-size: cover;
    }
  }
  & .navItem:nth-child(2) {
    .navInner {
      background: url('~/public/images/membership.svg') no-repeat center center;
      background-size: cover;
    }
  }
  & .navItem:nth-child(3) {
    .navInner {
      background: url('~/public/images/helpcenter.svg') no-repeat center center;
      background-size: cover;
    }
  }
  & .navItem:nth-child(4) {
    .navInner {
      background: url('~/public/images/mobility.svg') no-repeat center center;
      background-size: cover;
    }
  }

}

.btn {
  font-size: 18px;
  font-weight: bold;
  padding: 6px 12px;
  color: #0071d0;
}

.collapsed {
  display: none !important;
}

.toptipWrapper {
  padding-top: calc($minHeight / 2 - 80px );
  color: #1b1b1d;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  .note {
    font-size: 16px;
    color: #6b7280;
  }
}

.bold {
  font-weight: bold;
}

a {
  text-decoration: none;
}

.primaryLink {
  color: #0071d0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #005daa;
  }
}

.black {
  color: #1b1b1d;
}

.gray40 {
  color: #70707a;
}

.gray60 {
  color: #54545c;
}

.labelMedium {
  font-weight: 500;
}

.label2 {
  font-size: 22px;
}

.lalel2Val {
  font-size: 16px;
}

.head2 {
  font-size: 26px;
  color: #1b1b1d;
  margin-top: 8px;
  padding: 12px 0;
}

.MsgWrapper {
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 8px 15px;
  background-color: rgb(255, 244, 229);
  color: rgb(102, 60, 0);
}

.vmargin14 {
  margin: 14px 0;
}
.alertWrapper {
  margin-top: 15px;
  font-size: 16px;
  justify-content: center;
}
@media (max-width: 576px) {
  .pageContentContainer {
    width: 100%;
    box-sizing: border-box;
  }
  .toptipWrapper {
    padding-top: calc($minHeight / 2 - 50px - 80px);
  }
  .navWrapper {
    .navInner {
      height: 60px;
      line-height: 20px;
      padding: 20px 0 20px 15px;
    }
    .navItem {
      width: 50%;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .navWrapper {
    .navInner {
      height: 60px;
      line-height: 20px;
      padding: 20px 0 20px 15px;
    }
  }
  .pageContentContainer {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .navWrapper {
    .navInner {
      height: 80px;
      padding: 20px 0 20px 15px;
    }
  }
  .pageContentContainer {
    width: 740px
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  .navWrapper {
    .navInner {
      height: 80px;
      line-height: 20px;
      padding: 30px 0 30px 15px;
    }
  }
  .pageContentContainer {
    width: 960px;
  }
}

// @media (min-width: 1200px) and (max-width: 1439px) {

// }

@media (min-width: 1200px) and (max-width: 1439px) {
  .App-header {
    height: 68px;
  }
  .pageContentContainer {
    width: 1140px
  }
}

@media (min-width: 1440px) {
  .App-header {
    height: 68px;
  }
  .pageContentContainer {
    width: 1380px
  }
}