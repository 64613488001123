$breakpoint870: 870px;
$breakpoint1024: 1024px;
$breakpoint1025: 1025px;
$breakpoint871: 871px;
$breakpoint1126: 1126px;
$fontHead: 22px;
.bodyInner {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
}

.formWrapper {
  display: flex;
  // height: 50px;
  .formItemSelect {
    height: 50px;
    font-size: $fontHead;
    font-weight: 400;
  }
  .formItemInput {
    height: 50px;
    input {
      height: 100%;
      font-size: 24px;
      font-weight: 400;
    }
  }
  .formItemBtn {
    height: 50px;
    flex: 0 0 auto;
  }
}

.detailWrapper {
  flex: 0 0 40%;
  padding: 20px;
}

.formItemLabel {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  text-align: left;
}

.mandatoryMark {
  color: #e71316;
}

.formItem {
  -webkit-appearance: none;
  appearance: none;
  margin-top: 0.8rem;
  line-height: 1.8rem;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.formItemSelect {
  height: 50px;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 0 !important;
  background-color: #f9f9f9;
  & > div {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  & fieldset {
    border: none !important;
    border-radius: 0 !important;
  }
}

.formItemInput {
  height: 50px;
  position: relative;
  width: 260px;
  border: 1px solid #ccc !important;
  border-left: none !important;
  font-size: 1rem;
  // padding-top: 12px !important;
  // padding-bottom: 12px !important;
  & input {
    display: block;
    width: 100%;
    border: none !important;
    font-size: 1rem;
    line-height: 22px;
    height: 50px;
    padding-left: 12px;
    padding-right: 30px;
    background-color: #fff;
    &:-internal-autofill-selected {
      appearance: none !important;
      background-color: #fff !important;
    }
  }
  .clearIcon {
    color: #6b7280;
    position: absolute;
    right: 6px;
    top: 13px;
  }
}

.formItemBtn {
  height: 50px;
  width: 100px;
  appearance: none;
  line-height: 1.8rem;
  border: 1px solid #ccc;
  border-left: none;
  background-color: #4a4a4a;
  color: #fff;
  font-size: 1.2rem;
  & svg {
    width: 32px;
    height: 32px;
    vertical-align: middle;
  }
  .disabled {
    background: #ccc;
    color: #fff;
  }

  ::-moz-focus-inner {
    border: 0;
  }

  :focus {
    outline: none;
  }
}

.loadingMask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;

  .loadingBody {
    margin-top: 38vh;
    text-align: center;
  }
}
// mobile
@media screen and (max-width: 566px) {
  .formWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .formItemSelect {
      width: 100%;
      border-bottom: none !important;
    }
    .formItemInput {
      flex: 1 1 auto;
      border-left: 1px solid #ccc !important;
    }
    .formItemBtn {
      width: auto !important;
      flex: 1 1 auto;
    }
  }
  .bodyInner {
    flex-direction: column;
  }

  .detailWrapper {
    flex: 0 0 auto;
  }
}

// tablet
@media screen and (min-width: 567px) and (max-width: $breakpoint1024) {
  .formWrapper {
    width: 100%;
    .formItemSelect {
      flex: 0 1 auto;
    }
    .formItemInput {
      flex: 1 1 auto;
    }
  }
  .detailWrapper {
    flex: 0 0 auto;
  }
}

@media screen and (min-width: $breakpoint1025) and (max-width: 1199px) {
  .formWrapper {
    .formItemInput {
      width: 340px;
    }
  }

  .detailWrapper {
    flex: 0 0 50%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1299px) {
  .formWrapper {
    .formItemInput {
      width: 360px;
    }
  }
  .detailWrapper {
    flex: 0 0 50%;
  }
}
@media screen and (min-width: 1300px) {
  .formWrapper {
    .formItemInput {
      width: 400px;
    }
  }
  .detailWrapper {
    flex: 0 0 44%;
  }
}
